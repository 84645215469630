import React, { useState } from "react"
import { useApp } from "../../../../hooks/useApp"
import { useCheckoutContext } from "../../../../hooks/useCheckout"

export const withSanityProductCard = Component => ({
  name = "Card",
  item,
  showAddToCart = false,
}) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { giftcardHandle } = useCheckoutContext()

  const isParentProduct = item.product?.tags?.some(tag => tag === "parent")

  const swatchesOrderList = item.product?.tags
    ?.filter(tag => tag?.includes("swatch_order"))
    ?.sort((a, b) => a?.split(":")?.[2] - b?.split(":")?.[2])
    ?.map(tag => tag?.split(":")?.[1])

  const products = item.group
    .filter(product => {
      return product?.publishedAt && new Date(product?.publishedAt).getTime() < new Date().getTime()
    })
    .map(product => ({
      ...product,
      colourCode: product?.options?.find(option => option.name === "Colour")
        ?.values?.[0],
    }))
    .sort(
      (a, b) => {
        let indexOfA = swatchesOrderList.indexOf(a.colourCode)
        let indexOfB = swatchesOrderList.indexOf(b.colourCode)
        indexOfA = indexOfA < 0 ? swatchesOrderList.length : indexOfA
        indexOfB = indexOfB < 0 ? swatchesOrderList.length : indexOfB
        return indexOfA - indexOfB
      },
    )

  const findFirstProductOfSwatch = (products): any => {
    let ret = null
    swatchesOrderList.forEach(swatch => {
      if (!ret) {
        ret = products.find(product => product.colourCode === swatch)
      }
    })
    return ret
  }

  const defaultProductHandle = isParentProduct
    ? swatchesOrderList.length > 0
      ? findFirstProductOfSwatch(products)?.handle
      : products[0].handle
    : item.product.handle

  const [activeProductHandle, setActiveProductHandle] = useState(
    defaultProductHandle,
  )

  const [hoverActiveProductHandle, setHoverActiveProductHandle] = useState(
    defaultProductHandle,
  )

  const productsHashTable = Object.fromEntries(
    products.map(product => [product.handle, product]),
  )

  const activeProduct = productsHashTable[activeProductHandle]
  const hoverActiveProduct = productsHashTable[hoverActiveProductHandle]

  const isGiftCard = item.handle === giftcardHandle

  Component.displayName = name
  return (
    <Component
      defaultProductHandle={defaultProductHandle}
      activeProduct={activeProduct}
      hoverActiveProduct={hoverActiveProduct}
      url={
        isGiftCard
          ? `${routes.GIFTCARD}`
          : `${routes.PRODUCT}/${activeProductHandle}`
      }
      products={products}
      activeProductHandle={activeProductHandle}
      setActiveProductHandle={setActiveProductHandle}
      setHoverActiveProductHandle={setHoverActiveProductHandle}
      swatchesOrderList={swatchesOrderList}
      isGiftCard={isGiftCard}
      showAddToCart={showAddToCart}
    />
  )
}
