import React from "react"

import { withProductsCarousel } from "./withProductsCarousel"
import { SanityProductCard } from "../../Product/Card/SanityProduct/SanityProductCard"
import {
  Wrapper,
  SliderWrapper,
  Title,
  Content,
  ButtonWrapper,
} from "./ProductsCarouselStyles"
import { TextButtonsGrid } from "../../TextButtonsGrid/TextButtonsGrid"
import { Slider, Slide } from "../../Slider/Slider"
import { Icon } from "../../Icon/Icon"
import { Next, Prev } from "../../Slider/SliderStyles"
import { ButtonLink } from "../../ButtonLink/ButtonLink"
import { HeroCard } from "../Hero/Card/HeroCard"

export const ProductsCarousel = withProductsCarousel(
  ({
    title,
    button,
    items,
    content,
    layout,
    id,
    showAddToCart,
    handlePromoClick,
    firstCard,
    firstCardRatio,
  }): JSX.Element | null =>
    items.length > 0 ? (
      <Wrapper layout={layout}>
        <Title layout={layout}>{title}</Title>
        <Content>
          <TextButtonsGrid content={content} />
        </Content>
        <SliderWrapper>
          <Slider
            className={"product-carousel"}
            layout={
              layout === "normal" && typeof content !== "object"
                ? "product-carousel-4"
                : "product-carousel-3"
            }
            prevEl={`#id-${id}-carousel-prev`}
            nextEl={`#id-${id}-carousel-next`}
          >
            {firstCard ? (
              <Slide>
                <HeroCard card={firstCard} ratio={firstCardRatio} />
              </Slide>
            ) : null}
            {items?.map((item, index) => (
              <Slide key={`${item.title}+${index}`} onClick={handlePromoClick}>
                <SanityProductCard item={item} showAddToCart={showAddToCart} />
              </Slide>
            ))}
          </Slider>
          <Prev id={`id-${id}-carousel-prev`}>
            <Icon name={"arrowLeft"} />
          </Prev>
          <Next id={`id-${id}-carousel-next`}>
            <Icon name={"arrow"} />
          </Next>
        </SliderWrapper>
        {button?.link && (
          <ButtonWrapper>
            <ButtonLink
              link={button?.link}
              style={button?.style}
              size={button?.size}
              wide
            />
          </ButtonWrapper>
        )}
      </Wrapper>
    ) : null,
)

export default ProductsCarousel
