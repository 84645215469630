import React from "react"
import { Link } from "gatsby"

import { withSanityProductCard } from "./withSanityProductCard"
import {
  Wrapper,
  InnerWrapper,
  ImageWrapper,
  DetailsWrapper,
  Details,
  Wishlist,
  Title,
} from "../CardStyles"
import { ImageWithHover } from "../../../ImageWithHover/ImageWithHover"
import { Price } from "../../Price/Price"
import { Badge } from "../../Badge/Badge"
import { Message } from "../../Message/Message"
import { Wishlist as WishlistIcon } from "../../Wishlist/Wishlist"
import { CardSwatches as Swatches } from "../../Swatches/Card/CardSwatches"
import { SanityProductCardAddToCart as AddToCart } from "./AddToCart/SanityProductCardAddToCart"
import { StarRating } from "../../../Okendo/StarRating"

export const SanityProductCard = withSanityProductCard(
  ({
    defaultProductHandle,
    activeProduct,
    hoverActiveProduct,
    url,
    products,
    activeProductHandle,
    setActiveProductHandle,
    setHoverActiveProductHandle,
    isGiftCard,
    showAddToCart,
  }) => (
    <Wrapper>
      <InnerWrapper>
        <ImageWrapper
          as={Link}
          to={url}
          title={activeProduct?.title}
          image={hoverActiveProduct?.images?.length > 0}
        >
          <ImageWithHover
            image={hoverActiveProduct?.images?.[0]}
            hoverImage={hoverActiveProduct?.images?.[1]}
            ratio={"15/19"}
          />
        </ImageWrapper>
        {!isGiftCard && (
          <Swatches
            products={products}
            defaultProductHandle={defaultProductHandle}
            activeProductHandle={activeProductHandle}
            setActiveProductHandle={setActiveProductHandle}
            setHoverActiveProductHandle={setHoverActiveProductHandle}
          />
        )}
        <DetailsWrapper>
          <Badge tags={activeProduct?.tags} layout={"card"} />
          <Details>
            <Title as={Link} to={url} title={activeProduct?.title}>
              {activeProduct?.title}
            </Title>
            <Wishlist>
              {!isGiftCard && <WishlistIcon product={activeProduct} />}
            </Wishlist>
          </Details>
          {!isGiftCard && (
            <Details>
              <Price variant={activeProduct?.variants?.[0]} layout={"card"} />
            </Details>
          )}
          <Message tags={activeProduct?.tags} layout={"card"} />
          <StarRating productId={activeProduct?.id} />
        </DetailsWrapper>
      </InnerWrapper>
      {showAddToCart && (
        <div>
          <AddToCart activeProduct={activeProduct} />
        </div>
      )}
    </Wrapper>
  ),
)
