import React from "react"
import { useApp } from "../../../../../hooks/useApp"
import { useShopifyVariants } from "../../../../../hooks/useShopify"

export const withSanityProductCardAddToCart = Component => ({
  name = "SanityProductCardAddToCart",
  activeProduct,
}) => {
  const {
    config: {
      settings: {
        constraints: { SIZE },
      },
    },
  } = useApp()

  const { activeVariant, selectedOptions, handleVariant } = useShopifyVariants({
    product: activeProduct,
  })

  Component.displayName = name
  return (
    <Component
      product={activeProduct}
      options={activeProduct.options}
      activeVariant={activeVariant}
      selectedOptions={selectedOptions}
      handleVariant={handleVariant}
      SIZE={SIZE}
    />
  )
}
