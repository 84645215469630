import React, { useEffect, useState } from "react"

import { useAnalytics } from "../../../hooks/useAnalytics"
import { useShopify } from "../../../hooks/useShopify"

export const withProductsCarousel = Component => ({
  name = "ProductsCarousel",
  title,
  button = undefined,
  products,
  collection = undefined,
  content = undefined,
  layout = "featured",
  analytics = {},
  id = undefined,
  _key = undefined,
  showAddToCart = false,
  firstCard = undefined,
  firstCardRatio = undefined,
}) => {
  const {
    trackPromoImpression,
    VisibilitySensor,
    trackPromoClick,
  } = useAnalytics()
  const {
    getHandle,
    getGroupedProducts,
    getCollectionGroupedProducts,
  } = useShopify()

  const [items, setItems] = useState([])

  const fetchItems = async () => {
    const items =
      products?.length > 0
        ? await getGroupedProducts({
          products: products?.map(product => ({
            handle: getHandle(product),
            tags: product.tags,
          })),
          firstImages: 2,
          firstVariants: showAddToCart ? 50 : 1,
        })
        : getHandle(collection)?.length > 0
          ? await getCollectionGroupedProducts({
            collectionHandle: getHandle(collection),
            firstImages: 2,
            firstVariants: showAddToCart ? 50 : 1,
          })
          : []
    setItems(items.filter(item => item?.group?.length > 0))
  }

  //intentionally only run once at first render
  useEffect(() => {
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Component.displayName = name
  return (
    <VisibilitySensor
      onChange={visible => visible && trackPromoImpression({ ...analytics })}
    >
      <Component
        id={id || _key || "products"}
        title={title}
        button={button}
        items={items}
        content={content}
        layout={typeof content === "undefined" ? "normal" : layout}
        showAddToCart={showAddToCart}
        handlePromoClick={() => trackPromoClick({ ...analytics })}
        firstCard={firstCard}
        firstCardRatio={firstCardRatio}
      />
    </VisibilitySensor>
  )
}
